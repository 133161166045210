import * as React from 'react'
import {GiButton, GiModalHeader} from '../../components/system-ui'
import {
  Alert,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import './style.scss'
import ReactDatePicker from 'react-datepicker'
import {AiOutlineCalendar} from 'react-icons/ai'
import moment from 'moment'
import SelectedProductQuantity from './SelectedProductQuantity'
import {Field, Formik} from 'formik'
import * as Yup from 'yup'
import {CONFIG, CONNECTION} from '../../helper'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
// import {useSelector} from 'react-redux'
// import {RootState} from '../../redux/rootReducer'
import {MdCheckBox} from 'react-icons/md'
import {useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'

interface IAppProps {
  open: boolean
  data?: any
  onCloseModal: any
  openForm?: boolean
}

const formSchema = Yup.object().shape({
  qty: Yup.number().required('Quantity is required!'),
  date: Yup.date().required('Date is required!'),
  // delivery: Yup.string().required('Delivery is required'),
  descriptions: Yup.string().required('Description is requried'),
})

interface IInitialValues {
  qty: number
  date: any
  delivery: any
  gift_voucher: any
  scan_to_reward: any
  descriptions: any
}

const ModalFormAddInquiry: React.FunctionComponent<IAppProps> = (props) => {
  const {open, onCloseModal, data, openForm} = props
  const [loading, setLoadng] = React.useState(false)
  const [inititalValue, setInitialValue] = React.useState({
    qty: 0,
    date: moment(new Date()).toDate(),
    delivery: '',
    gift_voucher: '',
    scan_to_reward: data?.scan_to_reward || '',
    descriptions: '',
  })

  let magicCard = process.env.REACT_APP_QRGIFT_NAME || 'Magic Card'
  const magicCardPath = magicCard.toLowerCase().replace(' ', '-')

  // const [opens, setOpen] = React.useState('')
  // const toggle = (id: any) => {
  //   if (open === id) {
  //     setOpen('')
  //   } else {
  //     setOpen(id === opens ? '' : id)
  //   }
  // }

  // const {corporate_detail} = useSelector((state: RootState) => state.auth.company)

  const [success, setSuccess] = React.useState(openForm || false)
  // console.log('🚀 ~ corporate_detail:', corporate_detail.department)
  React.useEffect(() => {
    if (open) {
      setSuccess(false)
    }
  }, [open])

  const {corporate_id} = useSelector((state: RootState) => state.auth.company)

  return (
    <Modal
      className='modal-input-inquiry'
      isOpen={open}
      toggle={onCloseModal}
      size='lg'
      keyboard={false}
      backdrop='static'
    >
      <Formik
        onSubmit={async (values: IInitialValues) => {
          // console.log('🚀 ~ onSubmit={ ~ values:', values)
          setLoadng(true)
          // console.log(moment(values.date).format('MMMM Do YYYY, h:mm:ss'))
          const messages = `**Required By :** ${moment(values.date).format(
            'MMMM Do YYYY, h:mm:ss'
          )}\n\n**Quantity :** ${values.qty}\n\n**Gift Voucher :** ${
            values.gift_voucher === 'on' ? 'Yes' : 'No'
          }
           \n\n**${process.env?.REACT_APP_QRGIFT_NAME} :** ${
            values.scan_to_reward === 'on' ? 'Yes' : 'No'
          }\n\n**Message :** ${values.descriptions}
          `
          const dataToSend = {
            category: 'Physical Gift',
            application: 'b2b-dashboard',
            department: 'business-development',
            title: 'Physical Gift Enquiry',
            description: messages,
            details: {
              company_id: corporate_id,
            },
          }

          try {
            const config = CONFIG({url: '/support-ticket/create', data: dataToSend, method: 'POST'})
            const resp = await CONNECTION(config)
            const {code} = resp
            if (code === 200) {
              setLoadng(false)
              // toast.success(
              //   'Thank you! Our team will contact you as soon as possible to assist with your enquiry.'
              // )
              // props.onCloseModal()
              setSuccess(true)
            } else {
              setLoadng(false)
              // toast.warning(
              //   'Something went wrong. Please try submitting your enquiry again, or email us at grow@giftano.com for assistance.'
              // )
            }
          } catch (error) {
            toast.warning(
              'Something went wrong. Please try submitting your enquiry again, or email us at grow@giftano.com for assistance.'
            )
            setLoadng(false)
          }
        }}
        initialValues={inititalValue}
        validationSchema={formSchema}
        enableReinitialize
      >
        {({values, errors, setFieldValue, handleSubmit}) => {
          if (!success) {
            return (
              <Form onSubmit={handleSubmit}>
                <GiModalHeader toggle={onCloseModal}>Physical Gift Card Enquiry</GiModalHeader>
                <ModalBody>
                  <Card>
                    <CardBody>
                      <Alert color='secondary' className='inquiry-note'>
                        Please fill out the form below, and our team will get in touch with you to
                        assist with your enquiry.
                      </Alert>
                      <div className=''>
                        <div className='section-inquiry-1'>
                          <FormGroup className='form-group mb-0'>
                            <Label className='label-section'>Quantity</Label>
                            <div className='qty-group-inquiry'>
                              <SelectedProductQuantity
                                initialQty={values.qty}
                                onQtyChange={(newQty) => {
                                  setFieldValue('qty', newQty)
                                }}
                              />
                            </div>

                            {errors.qty ? <FormText>{errors.descriptions}</FormText> : ''}
                          </FormGroup>
                          <FormGroup className='form-group mb-0'>
                            <Label className='label-section'>Required by</Label>
                            <Field>
                              {() => {
                                return (
                                  <ReactDatePicker
                                    placeholderText='Choose Date'
                                    icon={
                                      <AiOutlineCalendar
                                        style={{
                                          fontSize: '21px',
                                          marginTop: '5px',
                                          marginLeft: '5px',
                                          color: '#909090',
                                        }}
                                      />
                                    }
                                    showIcon={true}
                                    timeIntervals={60}
                                    selected={values.date}
                                    dateFormat={'dd-MMM-yyyy'}
                                    className='form-control py-3 px-4 ps-13 w-100'
                                    onChange={(date) => {
                                      setFieldValue('date', date)
                                    }}
                                  />
                                )
                              }}
                            </Field>
                            {errors.date ? <FormText>{errors.date}</FormText> : ''}
                          </FormGroup>
                        </div>
                      </div>
                      <div className='d-none'>
                        <Label className='label-group-inquiry label-section'>Delivery Option</Label>

                        <FormGroup className='delivery-opt'>
                          <div className='input-radio-inquiry'>
                            <Field name='delivery'>
                              {({field}: any) => {
                                const {name} = field
                                return (
                                  <Input
                                    type='radio'
                                    checked={values.delivery === 'Self-Collection'}
                                    name={name}
                                    id='g'
                                    value='Self-Collection'
                                    onClick={() => {
                                      setFieldValue(name, '')
                                    }}
                                    onChange={(e: any) => {
                                      const {target} = e
                                      const {value} = target
                                      if (values.delivery !== value) {
                                        setFieldValue(name, value)
                                      } else {
                                        setFieldValue(name, '')
                                      }
                                    }}
                                  />
                                )
                              }}
                            </Field>
                            <Label for='g'>
                              Self-Collection at Giftano Office - One George Street, Level 10
                            </Label>
                          </div>
                          <div className='input-radio-inquiry'>
                            <Field name='delivery'>
                              {({field}: any) => {
                                const {name} = field
                                return (
                                  <Input
                                    type='radio'
                                    name={name}
                                    id='d'
                                    value='delivery'
                                    checked={values.delivery === 'delivery'}
                                    onClick={() => {
                                      setFieldValue(name, '')
                                    }}
                                    onChange={(e: any) => {
                                      const {target} = e
                                      const {value} = target
                                      if (values.delivery !== value) {
                                        setFieldValue(name, value)
                                      } else {
                                        setFieldValue(name, '')
                                      }
                                    }}
                                  />
                                )
                              }}
                            </Field>
                            <Label for='d'>Delivery</Label>
                          </div>
                        </FormGroup>
                      </div>
                      <div className=''>
                        <div className=''>
                          <Label className='label-section'>Select Product</Label>
                          <div className='section-inquiry-2'>
                            <div className='voucher col-item'>
                              <div className='item'>
                                <div>
                                  <Label>Normal Gift Cards</Label>
                                  <Field name='product'>
                                    {() => {
                                      return (
                                        <Input
                                          type='checkbox'
                                          checked={values.gift_voucher === 'on'}
                                          onChange={(e) => {
                                            const {target} = e
                                            const {value} = target
                                            setFieldValue(
                                              'gift_voucher',
                                              values.gift_voucher === 'on' ? '' : 'on'
                                            )
                                          }}
                                        />
                                      )
                                    }}
                                  </Field>
                                </div>
                              </div>
                            </div>
                            <div className='reward col-item'>
                              <div className='item'>
                                <div>
                                  <Label>
                                    Unactivated Gift Cards <br />(
                                    {process.env?.REACT_APP_QRGIFT_NAME})
                                  </Label>
                                  <Input
                                    type='checkbox'
                                    checked={values.scan_to_reward === 'on'}
                                    onChange={(e) => {
                                      const {target} = e
                                      const {value} = target
                                      setFieldValue(
                                        'scan_to_reward',
                                        values.scan_to_reward === 'on' ? '' : 'on'
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 className='lable-key-feature'>Key Features</h5>

                        <div className='section-inquiry-3'>
                          <div className='col-item'>
                            <div className='header-col flex justify-content-between'>
                              <Label>Normal Gift Cards</Label>
                            </div>
                            <div className='body-col'>
                              <ul>
                                <li>
                                  <MdCheckBox />
                                  No activation needed
                                </li>
                                <li>
                                  <MdCheckBox />
                                  Validity begins once Giftano distributes voucher to your office
                                </li>
                                <li>
                                  <MdCheckBox />
                                  Not customisable
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className='col-item'>
                            <div className='header-col d-flex justify-content-between'>
                              <Label>
                                Unactivated Gift Cards <br />({process.env?.REACT_APP_QRGIFT_NAME})
                              </Label>
                              <Link to={`/about-${magicCardPath}`} className='text-warning'>
                                Learn More
                              </Link>
                            </div>
                            <div className='body-col'>
                              <ul>
                                <li>
                                  <MdCheckBox />
                                  Requires activation
                                </li>
                                <li>
                                  <MdCheckBox />
                                  Future gifting
                                </li>
                                <li>
                                  <MdCheckBox />
                                  Validity begins only when you activate the cards
                                </li>
                                <li>
                                  <MdCheckBox />
                                  Customisable with logo and brand colours
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <Label className='label-section'>Message to Giftano Team</Label>

                        <FormGroup className='frm-group-msg'>
                          <Input
                            type='textarea'
                            rows='5'
                            placeholder='Write message here...'
                            onChange={(e) => {
                              const {target} = e
                              const {value} = target
                              setFieldValue('descriptions', value)
                            }}
                          />
                        </FormGroup>
                        {errors.descriptions ? <FormText>{errors.descriptions}</FormText> : ''}
                      </div>
                    </CardBody>
                  </Card>
                  <div className='action-button'>
                    <GiButton
                      color='warning'
                      type='submit'
                      className='rounded-pill px-15'
                      disabled={loading}
                    >
                      {loading ? 'Please wait...' : 'Send'}
                    </GiButton>
                  </div>
                </ModalBody>
              </Form>
            )
          }
          return (
            <>
              <GiModalHeader toggle={onCloseModal}>Physical Gift Enquiry</GiModalHeader>

              <ModalBody>
                <div className='text-center'>
                  <h4>Thank you!</h4>
                  <p>Our team will contact you as soon as possible to assist with your enquiry.</p>
                </div>
              </ModalBody>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalFormAddInquiry
