import axios from 'axios'
import {Dispatch} from 'react'
import {IAction} from '../store'
import {ERROR_STRAPI, LOADING_STRAPI, SUCCESS_STRAPI} from './StrapiActionTypes'
import qs from 'qs'
import {flattenObj} from '../../../_metronic/helpers/FlattenObject'

export const ActionGetStrapiContent = () => async (dispatch: Dispatch<IAction>) => {
  dispatch({type: LOADING_STRAPI})
  try {
    let query = qs.stringify(
      {
        // populate: '*',
        populate: [
          'banner.image.*',
          'promotion.media.*',
          'catalog_giftbox_logo',
          'catalog_giftbox_images',
          'catalog_giftcard_logo',
          'catalog_giftcard_images',
          'payment_options.icon.*',
          'create_orders_method.icon.*',
          'qr_gift_banner.*',
          'qr_gift_banner.image',
        ],
      },
      {encode: true}
    )
    let res = await axios.get(process.env.REACT_APP_BASEURL_STRAPI + `/business-dashboard?${query}`)
    if (res.status === 200) {
      let flattenedData = flattenObj(res.data)
      dispatch({type: SUCCESS_STRAPI, payload: {data: flattenedData}})
    } else {
      dispatch({
        type: ERROR_STRAPI,
        payload: {error: (res.data && res.data.message) || 'Unknown error'},
      })
    }
  } catch (err) {
    dispatch({type: ERROR_STRAPI, payload: {error: err}})
  }
}
